import { useState } from 'react';
import './Navigation.scss';
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material/styles';

const Navigation = () => {
	const [selectedRoute, setSelectedRoute]: any = useState(
		window.location.pathname
	);
	const navigate: any = useNavigate();
	const theme = useTheme();

	const routesData = [
		{ routeName: 'DiGGi - GPT', routePath: '/insights/chatbot' },
		{ routeName: 'Predictive Scoring', routePath: '/insights/predictive-scoring' },
		// { routeName: 'MMM', routePath: '/insights/MMM' },
	];

	return (
		<div className='navigation'>
			<div
				style={{
					borderRadius: '6px',
					padding: '2px',
					position: 'relative',
					top: '-1.2rem',
					left: '11.2rem',
					fontSize: 'x-small',
					backgroundColor: '#9149ff',
				}}
			>
				Beta
			</div>
			{routesData.map((item: any, index: any) => (
				<div
					className={
						selectedRoute == item.routePath ? 'selectedNavigationBorder' : ''
					}
					style={{ cursor: 'pointer' }}
					onClick={() => {
						navigate(item.routePath);
						setSelectedRoute(item.routePath);
					}}
					key={item.routePath}
				>
					<div
						className={`${
							selectedRoute === item.routePath && theme.palette.mode === 'dark'
								? 'selectedNavigationBorder'
								: theme.palette.mode === 'light' &&
								  selectedRoute === item.routePath
								? 'selectedNavigationBorderLight'
								: 'navigationBorder'
						}`}
						style={{
							color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
						}}
						// className={
						// 	selectedRoute == item.routePath
						// 		? 'selectedNavigationBorder'
						// 		: 'navigationBorder'
						// }
					>
						<div className='navigationContent'>
							<div>{item.routeName}</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default Navigation;
