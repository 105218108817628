import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PredictiveScoring from './containers/AdvertisementChannel/PredictiveScoring';

// Function to resolve loading chunk issue

const lazyWithRetry = (componentImport: any, name: any) => async () => {
	const pageHasAlreadyBeenForceRefreshed = JSON.parse(
		window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
	);

	try {
		const component = await componentImport();

		window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false');

		return component;
	} catch (error) {
		if (!pageHasAlreadyBeenForceRefreshed) {
			// Assuming that the user is not on the latest version of the application.
			// Let's refresh the page immediately.
			window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true');
			return window.location.reload();
		}

		// The page has already been reloaded
		// Assuming that user is already using the latest version of the application.
		// Let's let the application crash and raise the error.
		throw error;
	}
};

const AIChatBot = lazy(
	lazyWithRetry(() => import('./containers/AIChatBot/AIChatBot'), 'aiChatBot')
);

const ChooseAccount = lazy(
	lazyWithRetry(
		() => import('./containers/ChooseAccount/ChooseAccount'),
		'chooseAccount'
	)
);

const Login = lazy(
	lazyWithRetry(() => import('./containers/Login/Login'), 'login')
);

const InvitedUser = lazy(
	lazyWithRetry(() => import('./containers/Login/InvitedUser'), 'invitedUser')
);

const EditProfile = lazy(
	lazyWithRetry(
		() => import('./containers/EditProfile/EditProfile'),
		'editProfile'
	)
);

const MMM = lazy(
	lazyWithRetry(
		() => import('./containers/MMM/MMM'),
		'editProfile'
	)
);

const AIEditProfile = lazy(
	lazyWithRetry(
		() => import('./containers/EditProfile/AIEditProfile'),
		'MMM'
	)
);

const ResetPassword = lazy(
	lazyWithRetry(
		() => import('./containers/Login/ResetPassword'),
		'ResetPassword'
	)
);

const ComingSoon = lazy(
	lazyWithRetry(
		() => import('./components/ComingSoon/ComingSoon'),
		'ComingSoon'
	)
);

const ChannelManager = lazy(
	() => import('./containers/ChannelManager/ChannelManagerContainer')
);

const ContentGrouping = lazy(
	lazyWithRetry(
		() => import('./containers/ContentGrouping/ContentGrouping'),
		'ContentGrouping'
	)
);
const AdvertisementChannel = lazy(
	lazyWithRetry(
		() => import('./containers/AdvertisementChannel/PredictiveScoring'),
		'PredictiveScoring'
	)
);

const AppRoutes: React.FC = () => (
	<Suspense fallback=''>
		<Routes>
			<Route path='/account' element={<ChooseAccount />} />

			<Route path='invited/login' element={<InvitedUser />} />
			<Route path='/login' element={<Login />} />
			<Route path='/AIEditProfile' element={<AIEditProfile />} />
			<Route path='/editProfile' element={<EditProfile />} />
			<Route path='/reset-password' element={<ResetPassword />} />
			<Route path='' element={<Navigate to='/login' />} />
			<Route path='/insights/predictive-scoring' element={<PredictiveScoring />} />
			<Route path='/insights/MMM' element={<MMM/>} />

			<Route path='/account/:id/:token' element={<ChooseAccount />} />

			<Route path='/insights/chatbot' element={<AIChatBot />} />

			<Route path='/settings/channel-manager' element={<ChannelManager />} />

			<Route path='/settings/content-grouping' element={<ContentGrouping />} />
			<Route path='/coming-soon' element={<ComingSoon />} />
		</Routes>
	</Suspense>
);

export default AppRoutes;
